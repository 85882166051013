import React from 'react';
import ReactDom from 'react-dom';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Header} from './Header/Header';
import {Footer} from './Footer/Footer';
import {Podcasts} from './Podcasts/Podcasts';
import {About} from './About/About';
import {Mews} from './Features/Mews/Mews';
import {AprilFools} from './Features/April/AprilFools';
import {HouseShow} from './Features/HouseShow/HouseShow';
import {Home} from './Home/Home';
import {init} from 'emailjs-com';
import '../node_modules/modern-normalize/modern-normalize.css';

init('user_Z8y1wEo02rhCX4NEa04Nt');

const appMount = document.getElementById('app-mount');
function App() {
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path='/vinyl' element={<AprilFools/>}/>
                <Route path='/features/mews' element={<Mews/>}/>
                <Route path='/features/house-show' element={<HouseShow/>}/>
                <Route path='/podcasts' element={<Podcasts/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/' element={<Home/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
}

ReactDom.render(<App/>, appMount);
