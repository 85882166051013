import React from 'react';
import './home.scss';

export function Home() {
    return (
        <section className='hero'>
            <div>
                <img src='/images/house-show/bouquet.jpeg'/>
            </div>
            <section>
                <h1>Bouquet</h1>
                <p>
                    from the<br/>
                    Pretty Fort<br/>
                    House Show
                </p>
                <p>
                    Photos by<br/>
                    Shiara Nivana<br/>
                    Photography
                </p>
                <p>
                    Thanks<br/>
                    for coming<br/>
                    ♥
                </p>
            </section>
            <div>
                <img src='/images/house-show/bouquet-2.jpg'/>
            </div>
        </section>
    );
}
