import React from 'react';
import './video.scss';

export function Video({embedId}) {
    return (
        <div className='video-embed'>
            <iframe
                width='853'
                height='480'
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
            />
        </div>
    )
}
