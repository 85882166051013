import React, {useEffect, useRef, useState} from 'react';
import {Waveform} from '../icons/Waveform';
import {sendForm} from 'emailjs-com';
import {validate} from 'email-validator';
import './form.scss';

export function Form(props) {
    const [formSent, setFormSent] = useState();
    const [emailIsValid, setEmailIsValid] = useState();
    const [message, setMessage] = useState();
    const form = useRef();
    const email = useRef();

    useEffect(() => {
        email.current.focus();
    }, []);

    function onSubmit(e) {
        e.preventDefault();
        setFormSent("Sending...");

        sendForm('service_9zkdoc9', 'template_0pwgdav', form.current).then(() => {
            setFormSent("Thanks! We'll get back to you soon.")
            setTimeout(props.hideForm, 2000);
        }, () => {
            setFormSent("There was an issue sending your message – please refresh the page and try again.")
        });
    }

    function checkEmail(email) {
        setEmailIsValid(validate(email));
    }

    return (
        <div className="form-wrapper" onClick={props.hideForm}>
            <section onClick={(e) => e.stopPropagation()}>
                <Waveform/>
                {
                    !formSent ? (
                        <form onSubmit={onSubmit} ref={form}>
                            <h6>Send us your music.</h6>
                            <span>Or your <i>favorite</i> music. Or your sick Twitter handle ideas. In any case, thanks for getting touch.</span>
                            <input
                                type="text"
                                name="reply_to"
                                ref={email}
                                onChange={(e) => checkEmail(e.target.value)}
                                placeholder="Your email"
                            />
                            <textarea
                                name="message"
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type your message, drop your links, etc."
                            />
                            <input
                                disabled={!message || !emailIsValid}
                                type="submit"
                                value="Send"
                            />
                        </form>
                    ) : (
                        <span>{formSent}</span>
                    )
                }

            </section>
        </div>
    );
}
