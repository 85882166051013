import React, {useEffect, useRef, useState} from 'react';
import './podcasts.scss';

export function Podcasts() {
    const section = useRef();
    const [mounted, setMounted] = useState();

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://widget.spreaker.com/widgets.js';
            section.current.appendChild(script);
        }
    });

    return (
        <section className='podcasts' ref={section}>
            <h2>Weekly Reviews</h2>
            <div>
                <a href='https://podcasts.apple.com/us/podcast/pretty-fort-weekly/id1451561204' target='_blank'>Apple Podcasts</a>
                <a href='https://open.spotify.com/show/7B4SnwB1c1pGIDDXineOMK?si=p1uN4buqSzyfLhiDuwUEWw&dl_branch=1' target='_blank'>Spotify</a>
                <a href='https://www.google.com/podcasts?feed=aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvMzMyNzQ3Mi9lcGlzb2Rlcy9mZWVk' target='_blank'>Google Podcasts</a>
            </div>
            <a className='spreaker-player' href='https://www.spreaker.com/show/pretty-fort-weekly' data-resource='show_id=3327472' data-width='100%' data-height='350px' data-theme='dark' data-playlist='show' data-playlist-continuous='false' data-chapters-image='false' data-episode-image-position='right' data-hide-logo='true' data-hide-likes='true' data-hide-comments='true' data-hide-sharing='false' data-hide-download='false'>Listen to 'Pretty Fort Weekly' on Spreaker.</a>
            <h2>Interviews, Premieres, and More</h2>
            <div>
                <a href='https://podcasts.apple.com/us/podcast/pretty-fort-interviews/id1451270590' target='_blank'>Apple Podcasts</a>
                <a href='https://open.spotify.com/show/1e8Zh7AjLtlGxfrHSl6VYE?si=JkRFBv4GRIyZqkKyTq27KQ&dl_branch=1' target='_blank'>Spotify</a>
                <a href='https://www.google.com/podcasts?feed=aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvMzMxODg1NS9lcGlzb2Rlcy9mZWVk' target='_blank'>Google Podcasts</a>
            </div>
            <a className='spreaker-player' href='https://www.spreaker.com/show/pretty-fort-interviews' data-resource='show_id=3318855' data-width='100%' data-height='350px' data-theme='dark' data-playlist='show' data-playlist-continuous='false' data-chapters-image='true' data-episode-image-position='right' data-hide-logo='true' data-hide-likes='true' data-hide-comments='true' data-hide-sharing='false' data-hide-download='true'>Listen to 'Pretty Fort Interviews' on Spreaker.</a>
        </section>
    );
}
