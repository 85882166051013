import React from 'react';
import {Video} from '../Video/Video';
import './houseshow.scss';

export function HouseShow() {
    return (
        <div className='house-show'>
            <img src='/images/house-show/1.png'/>
            <img src='/images/house-show/2.png'/>
            <img src='/images/house-show/3.png'/>
            <img src='/images/house-show/4.png'/>
            <section>
                <Video embedId={'yICGSgrEyVw'}/>
            </section>
        </div>
    );
}
