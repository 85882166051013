import React, {useEffect, useRef, useState} from 'react';
import {Hamburger} from './icons/Hamburger';
import {PaperPlane} from './icons/PaperPlane';
import {Form} from './Form/Form';
import {Menu} from './Menu/Menu';
import {Link} from 'react-router-dom';
import './header.scss';

export function Header() {
    const header = useRef();
    function onScroll() {
        header.current.classList.toggle('minimized', window.scrollY > 0);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    });

    const [showForm, setShowForm] = useState();
    const [menuClass, setMenuClass] = useState();

    return (
        <header ref={header}>
            <div>
                <div className='left'>
                    <Hamburger onClick={() => setMenuClass('show')} className='menu-icon'/>
                </div>
                <div className='center'>
                    <h1><Link to='/'>Pretty Fort</Link></h1>
                </div>
                <div className='right'>
                    <button onClick={() => setShowForm(true)}><span>Drop a Link</span><PaperPlane/></button>
                </div>
                {showForm && <Form hideForm={() => setShowForm(false)}/>}
                <Menu className={menuClass} hideMenu={() => setMenuClass('')}/>
            </div>
        </header>
    );
}
