import React, {useEffect, useRef, useState} from 'react';
import './mews.scss';

export function Mews() {
    const article = useRef();
    const [mounted, setMounted] = useState();
    const carousel = useRef();
    const scroll = useRef();
    function onScroll() {
        scroll.current.classList.toggle('hidden', carousel.current.scrollLeft > 0);
    }

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://widget.spreaker.com/widgets.js';
            article.current.appendChild(script);
        }

        carousel.current.addEventListener('scroll', onScroll);

        return () => {
            carousel.current.removeEventListener('scroll', onScroll);
        }
    });

    return (
        <article className='mews' ref={article}>
            <section className='title'>
                <video autoPlay={true} muted={true} loop={true} autobuffer="true" playsInline={true}>
                    <source src='/images/mews/background.mov' type='video/mp4'/>
                </video>
                <div>
                    <h1>Lights Dim, Curtains Close: Farewell to Vaudeville Mews</h1>
                </div>
            </section>
            <section className='photo-right'>
                <div>
                    <h3>The final show at Vaudeville Mews was about seven months ago.</h3>
                    <p><i>Holy shit.</i></p>
                    <p>It’s ironic, in a way, that we’re all grieving just now in October considering the Mews unknowingly closed its doors for the final time back in mid-March.</p>
                    <p>Performers across Des Moines, Iowa, and the country have been mourning the loss of what some call the “homebase” of Iowa’s music scene. Cause of death: COVID-19.</p>
                </div>
                <img src='/images/mews/sign.jpg'/>
            </section>
            <section className='carousel'>
                <img src='/images/mews/bar.jpg'/>
                <img src='/images/mews/tables.jpg'/>
            </section>
            <section className='overlay'>
                <div>
                    <p className='quote'>[Governor Kim Reynolds] went to Washington, met with the President, and then came back and said <i>Open up!</i>” says Amadeo Rossi, owner of both Vaudeville Mews and The Lift. “They did nothing to regulate people. So they started infecting each other in the bars and they know it.”</p>
                </div>
                <img src='/images/mews/door.jpg'/>
            </section>
            <section className='photo-left'>
                <img src='/images/mews/stickers.jpg'/>
                <div>
                    <p>Performers and employees are recognizing Rossi’s dedication to keeping the lights on in the Mews even when the numbers weren’t always in the black.</p>
                    <p>“It really was like Deo’s full compassion and belief in the music scene,” says Phil Young, performer and previous Mews sound engineer. “And Deo, out of the kindness of his heart, made sure it stayed open.”</p>
                </div>
            </section>
            <section className='carousel small' ref={carousel}>
                <img src='/images/mews/hallway.jpg'/>
                <img src='/images/mews/stage.jpg'/>
                <img src='/images/mews/monitors.jpg'/>
                <img src='/images/mews/lights.jpg'/>
                <span ref={scroll}>Scroll &rarr;</span>
            </section>
            <section className='photo-right'>
                <div>
                    <p>Vaudeville Mews doesn’t <i>really</i> need explaining to anyone who has ever participated in Des Moines’ ever-connected scene but the Mews is fucking dead and this is a proper eulogy.</p>
                    <h3>It was downtown, it was nearby, it was seemingly at the center of everything.</h3>
                    <p>"...Vaudeville and The Lift were like the perfect one-two punch,” says Young. “That’s not gonna ever exist again.”</p>
                </div>
                <img src='/images/mews/piano.jpg'/>
            </section>
            <section className='photo-left'>
                <img src='/images/mews/love.jpg'/>
                <div>
                    <p>And the Vaude was perfectly tucked into 4th Street. I remember the first time we scoped it out for a high school show and missing the entrance two or three times. But this also made the place intimate for anyone inside.</p>
                    <p>“It just made us wanna get loose,” says Diana Weishaar, keyboardist/vocalist in The Host Country. “It’s like you could be whoever you wanted to be ... It was like a cave of creativity.”</p>
                    <p>An essential part of the Mews’ lasting appeal was the opportunity not only to see national touring acts on the small stage but local favorites and newcomers taking the stage for their very first time.</p>
                </div>
            </section>
            <section className='layers'>
                <img src='/images/mews/sticky-note.jpg'/>
                <img src='/images/mews/bathroom.jpg'/>
                <div>
                    <p>Members of the Des Moines music community (including ourselves) have been sharing a number of posts on social media naming Vaudeville as the place where they played their first show.</p>
                    <p>“It was one of the few venues in Des Moines that just lets anybody express their music and show what they’ve got,” says Spencer Jenkins, guitarist/vocalist in 515. “I remember the first time [515] played the Mews ... saying, ‘I can’t believe I’ve got this opportunity. I’m so fucking young. And we really have no idea what the fuck we’re doing, but we get to play a show here for whoever wants to listen.’”</p>
                    <p>And while Ladd Askland, the Mews’ booking agent, did once double-book our high school band with an air-guitar contest, we forgive him for being stellar the other 99% of the time. ;)</p>
                </div>
            </section>
            <section className='carousel spaced'>
                <img src='/images/mews/console.jpg'/>
                <img src='/images/mews/cables.jpg'/>
                <img src='/images/mews/pod.jpg'/>
            </section>
            <section className='photo-left'>
                <img src='/images/mews/stairs.jpg'/>
                <div>
                    <p>It’s important to appreciate just how many shows there were at Vaudeville in any given week.</p>
                    <p>“I can’t think of any other place on the planet where I, as a local musician, can play there on a Saturday night and then on a Tuesday see a member of The Strokes playing,” says Tom Rue, bassist in The Host Country. “I could see literally [see some of my friends and then] some of my heroes in a matter of 48 hours in a space that was less square footage than my home.”</p>
                </div>
            </section>
            <section className='photo-right'>
                <div>
                    <p>Album release shows, festivals, 80/35 afterparties...</p>
                    <h3>If you hung around Vaudeville Mews long enough you were gonna see some shit.</h3>
                </div>
                <img src='/images/mews/mural.jpg'/>
            </section>
            <section className='overlay left'>
                <img src='/images/mews/booth.jpg'/>
                <div>
                    <p>Vaudeville Mews is leaving some fans with almost two decades of memories. But for those of us that hadn't been there since the very beginning, the <i>people</i> and welcoming atmosphere made it feel like we had.</p>
                </div>
            </section>
            <section className='overlay'>
                <img src='/images/mews/railing.jpg'/>
                <div>
                    <p className='quote'>It’s not about us,” says Rossi. “It’s about the artists who play. It’s about the people that come. It takes all of it. You don’t have a venue if you don’t have all of those things ... I have a strong feeling that good things are gonna continue to happen and hopefully we’ll have a better venue than this."</p>
                </div>
            </section>
            <section className='text'>
                <h4>Impossible.</h4>
                <img src='/images/mews/exterior.jpg'/>
            </section>
            <section className='credits'>
                <p>Story by Parker Reed</p>
                <p>Photos by Josh Petefish</p>
                <p>Produced by Chase Schweitzer</p>
            </section>
            <section className='podcast'>
                <p>Listen to our full interview with Amedeo Rossi below (or on a podcast service of your choice):</p>
                <div>
                    <a className='spreaker-player' href='https://www.spreaker.com/episode/41416598' data-resource='episode_id=41416598' data-width='100%' data-height='120px' data-theme='dark' data-playlist='false' data-playlist-continuous='false' data-autoplay='false' data-live-autoplay='false' data-chapters-image='false' data-episode-image-position='left' data-hide-logo='false' data-hide-likes='false' data-hide-comments='false' data-hide-sharing='false' data-hide-download='true'>Listen to "Amedeo Rossi" on Spreaker.</a>
                </div>
            </section>
            <section className="footer">
                <p>And please consider supporting the National Indpendent Venue Association:</p>
                <a href='https://www.saveourstages.com/'>saveourstages.com</a>
            </section>
        </article>
    );
}
