import React from 'react';

export function Waveform() {
    return (
        <svg 
            aria-hidden='true' 
            focusable='false' 
            role='img' 
            xmlns='http://www.w3.org/2000/svg' 
            viewBox='0 0 640 512'
        >
            <path 
                fill='currentColor' 
                d='M232 96h-16a8 8 0 0 0-8 8v304a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V104a8 8 0 0 0-8-8zm-96 96h-16a8 8 0 0 0-8 8v112a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V200a8 8 0 0 0-8-8zm-96 32H24a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zm384-96h-16a8 8 0 0 0-8 8v240a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V136a8 8 0 0 0-8-8zm192 96h-16a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zM520 64h-16a8 8 0 0 0-8 8v368a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM328 0h-16a8 8 0 0 0-8 8v496a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8z'
            />
        </svg>
    );
}
