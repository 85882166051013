import React from 'react';
import './footer.scss';

export function Footer() {
    const date = new Date();

    return (
        <footer>
            <span>&copy; Pretty Fort {date.getFullYear()}</span>
        </footer>
    )
}
