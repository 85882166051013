import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Close} from '../icons/Close';
import './menu.scss';

export function Menu(props) {
    const [showFeatures, setShowFeatures] = useState();
    const hideMenu = () => {
        props.hideMenu();
        setShowFeatures(false);
    }

    return (
        <React.Fragment>
            {props.className && <div className='menu-background'/>}
            <div className={`menu-wrapper ${props.className}`} onClick={hideMenu}>
                <nav onClick={(e) => e.stopPropagation()}>
                    <Close onClick={hideMenu}/>
                    <ul>
                        <li><Link onClick={hideMenu} to='/'>Home</Link></li>
                        <li onClick={() => setShowFeatures(!showFeatures)}>Features</li>
                        {
                            showFeatures && (
                                <li><ul>
                                    <li><Link onClick={hideMenu} to='/features/mews'>Lights Dim, Curtains Close: Farewell to Vaudeville Mews</Link></li>
                                    <li><Link onClick={hideMenu} to='/features/house-show'>The Pretty Fort House Show Invite</Link></li>
                                </ul></li>
                            )
                        }
                        <li><Link onClick={hideMenu} to='/podcasts'>Podcasts</Link></li>
                        <li><a href='https://prettyfort.bandcamp.com' target='_blank'>Tee Shirts!</a></li>
                        <li><Link onClick={hideMenu} to='/about'>About Us</Link></li>
                    </ul>
                    <div>
                        <a href='mailto:contact@prettyfort.com' target='_blank'>contact@prettyfort.com</a>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    );
}
