import React from 'react';
import './about.scss';

export function About() {
    return (
        <section className='about'>
            <img src='/images/two-boys.jpg'/>
            <p>
                Pretty Fort is the passion project of Chase Schweitzer and Parker Reed. They've been boys since high school and fell in love with the local scene via bummin' it at festivals, rippin' gigs at dive bars, and throwing 'bows whenever they could.
            </p>
            <p>
                Our projects wouldn't be possible without tons of help from <a href='https://www.youtube.com/user/KeneticFerret49' target='_blank'>Josh "Jorb" Petefish</a> (photo/video), Calvin Senteza (photo), <a href='https://greenwoodsound.com' target='_blank'>Brian Garrels</a> (audio) and the support of countless homies in the Iowa music community. Big shoutout to the <a href='https://www.desmoinesmc.com' target='_blank'>DMMC</a>, <a href='https://www.80-35.com' target='_blank'>80/35</a>, <a href='https://www.hinterlandiowa.com' target='_blank'>Hinterland Music Festival</a>, <a href='https://zzzrecords.com' target='_blank'>ZZZ Records</a>, and all the other friends that push us to keep on spinning.
            </p>
        </section>
    );
}
